import TaskManagerRequest from "../../services/TaskManagerRequest";

export const GET_INSPECTION_RECORDS = "GET_INSPECTION_RECORDS";
export const SET_ACTIVE_VEHICLE = "SET_ACTIVE_VEHICLE";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const SET_VEHICLES = "SET_VEHICLES";
export const GET_LATEST_REPORT = "GET_LATEST_REPORT";
export const SET_RECORD_INDEX = "SET_RECORD_INDEX";

export const getVehicleInspectionRecords = (vehicleId) => {
  return async (dispatch) => {
    const response = await TaskManagerRequest(
      "/inspection/records/" + vehicleId,
      {
        vehicleId: vehicleId,
      }
    );
    dispatch({
      type: GET_INSPECTION_RECORDS,
      value: response,
      vehicle: vehicleId,
    });
  };
};

export const setRecordIndex = (index) => {
  return {
    type: SET_RECORD_INDEX,
    value: index,
  };
};

export const setActiveVehicle = (vehicleId) => {
  return {
    type: SET_ACTIVE_VEHICLE,
    value: vehicleId,
  };
};

export const toggleLoading = (value) => {
  return {
    type: TOGGLE_LOADING,
    value: value,
  };
};

export const getInspectionVehicles = (groups) => {
  return async (dispatch) => {
    const response = await TaskManagerRequest("/inspection/vehicles", {
      groups,
    });
    dispatch({
      type: SET_VEHICLES,
      value: response,
    });
  };
};
