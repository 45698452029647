import {
  GET_INSPECTION_RECORDS,
  SET_ACTIVE_VEHICLE,
  SET_VEHICLES,
  TOGGLE_LOADING,
  SET_RECORD_INDEX,
} from "../actions/inspection.action";

const initialState = {
  activeVehicle: null,
  isLoading: false,
  inspectionRecords: {},
  inspectionVehicles: [],
  recordIndex: null,
};

const InspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return { ...state, isLoading: action.value };
    case GET_INSPECTION_RECORDS:
      return {
        ...state,
        inspectionRecords: {
          ...state.inspectionRecords,
          [action.vehicle]: action.value,
        },
      };
    case SET_ACTIVE_VEHICLE:
      return {
        ...state,
        activeVehicle: action.value,
      };
    case SET_VEHICLES:
      return {
        ...state,
        inspectionVehicles: action.value,
      };
    case SET_RECORD_INDEX:
      return {
        ...state,
        recordIndex: action.value,
      };

    default:
      return state;
  }
};

export default InspectionReducer;
